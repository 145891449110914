import React from "react"

class Projects {

    constructor(Name,Type,Status)
    {
        
        this.ProjectName = Name;
        this.ProjectType = Type;
        this.ProjectStatus = Status;

    }


}
export default Projects