import React from 'react';
import styled from "styled-components";
import '..//App.css';
import TitleBar from '../Components/TitleBar';

function About() {

  
    return (
        <>
            <div>
                
            </div>
        </>
    );
}
export default About;