import React from "react";

const Reports = () => {


    return (
        <>
        <h1>Hello</h1>
        </>
    );
}
export default Reports;