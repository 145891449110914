import React from 'react';
import styled from "styled-components";
import '..//App.css';
import TitleBar from '../Components/TitleBar';

function Contact() {

  
    return (
        <>
        <div className='MainContent'>

        </div>
        </>
    );
}
export default Contact;